import { PortfolioTransaction } from 'app/api/models/portfolio-transaction';

export const portfolioTransactions: PortfolioTransaction[] = [
    {
        id: '1',
        portfolio: {
            id: '1',
            name: 'Portfolio 1',
        },

        type: 'DEPOSIT',

        incomingAsset: {
            id: '6',
            symbol: 'USD',
            name: 'Dollar',
        },

        outgoingAsset: null,

        trade: null,

        description: 'Deposited Euros description',

        incomingAssetAmount: '400',
        incomingAssetAmountInDefaultAsset: '453',

        outgoingAssetAmount: null,
        outgoingAssetAmountInDefaultAsset: null,

        executedAt: '2019-06-24T11:48:07.883', // date time
    },
    {
        id: '2',
        portfolio: {
            id: '1',
            name: 'Portfolio 1',
        },
        type: 'DEPOSIT',

        incomingAsset: {
            id: '2',
            symbol: 'EUR',
            name: 'Euro',
        },

        outgoingAsset: null,

        trade: null,

        description: 'Deposited Euros',

        incomingAssetAmount: '400',
        incomingAssetAmountInDefaultAsset: '453',

        outgoingAssetAmount: null,
        outgoingAssetAmountInDefaultAsset: null,

        executedAt: '2019-06-24T11:48:07.883', // date time
    },
    {
        id: '3',
        portfolio: {
            id: '1',
            name: 'Portfolio 1',
        },
        type: 'DEPOSIT',

        incomingAsset: {
            id: '2',
            symbol: 'EUR',
            name: 'Euro',
        },

        outgoingAsset: null,

        trade: null,

        description: 'Deposited Euros description',

        incomingAssetAmount: '400',
        incomingAssetAmountInDefaultAsset: '453',

        outgoingAssetAmount: null,
        outgoingAssetAmountInDefaultAsset: null,

        executedAt: '2019-06-24T11:48:07.883', // date time
    },
    {
        id: '4',
        portfolio: {
            id: '1',
            name: 'Portfolio 1',
        },
        type: 'WITHDRAWAL',

        incomingAsset: null,

        outgoingAsset: {
            id: '11',
            symbol: 'CHF',
            name: 'Swiss Franc',
        },

        trade: null,

        description: 'Withdrawed Swiss Francs',

        incomingAssetAmount: null,
        incomingAssetAmountInDefaultAsset: null,

        outgoingAssetAmount: '214',
        outgoingAssetAmountInDefaultAsset: '245',

        executedAt: '2019-06-24T11:48:07.883', // date time
    },
    {
        id: '5',
        portfolio: {
            id: '1',
            name: 'Portfolio 1',
        },
        type: 'REALIZED_LOSS',

        incomingAsset: {
            id: '3',
            symbol: 'BTC',
            name: 'Bitcoin',
        },

        outgoingAsset: {
            id: '6',
            symbol: 'USD',
            name: 'Dollar',
        },

        trade: {
            id: '2',
            name: 'BTCUSD',
            tradeSide: 'LONG',
        },

        description: 'Some description',

        incomingAssetAmount: '-0.4673',
        incomingAssetAmountInDefaultAsset: '-13421',

        outgoingAssetAmount: null,
        outgoingAssetAmountInDefaultAsset: null,

        executedAt: '2019-06-24T11:48:07.883', // date time
    },
    {
        id: '6',
        portfolio: {
            id: '1',
            name: 'Portfolio 1',
        },
        type: 'REALIZED_PROFIT',

        incomingAsset: {
            id: '3',
            symbol: 'BTC',
            name: 'Bitcoin',
        },

        outgoingAsset: {
            id: '6',
            symbol: 'USD',
            name: 'Dollar',
        },

        trade: {
            id: '2',
            name: 'BTCUSD',
            tradeSide: 'SHORT',
        },

        description: null,

        incomingAssetAmount: '0.3462',
        incomingAssetAmountInDefaultAsset: '5637',

        outgoingAssetAmount: null,
        outgoingAssetAmountInDefaultAsset: null,

        executedAt: '2019-06-24T11:48:07.883', // date time
    },
    {
        id: '7',
        portfolio: {
            id: '1',
            name: 'Portfolio 1',
        },
        type: 'CONVERT',

        incomingAsset: {
            id: '1',
            symbol: 'USDT',
            name: 'Tether',
        },

        outgoingAsset: {
            id: '2',
            symbol: 'EUR',
            name: 'Euro',
        },

        trade: null,

        description: 'Converted Euro for USDT',

        incomingAssetAmount: '400',
        incomingAssetAmountInDefaultAsset: '453',

        outgoingAssetAmount: '314',
        outgoingAssetAmountInDefaultAsset: '445',

        executedAt: '2010-08-24T11:48:07.883', // date time
    },
    {
        id: '8',
        portfolio: {
            id: '1',
            name: 'Portfolio 1',
        },
        type: 'DEPOSIT',

        incomingAsset: {
            id: '11',
            symbol: 'CHF',
            name: 'Swiss Franc',
        },

        outgoingAsset: null,

        trade: null,

        description: 'Deposited Swiss Francs description',

        incomingAssetAmount: '400',
        incomingAssetAmountInDefaultAsset: '453',

        outgoingAssetAmount: null,
        outgoingAssetAmountInDefaultAsset: null,

        executedAt: '2019-06-24T11:48:07.883', // date time
    },
];
