import { AuthMockApi } from 'app/mock-api/common/auth/api';
import { MessagesMockApi } from 'app/mock-api/common/messages/api';
import { NotificationsMockApi } from 'app/mock-api/common/notifications/api';
import { UserMockApi } from 'app/mock-api/common/user/api';
import { AssetMockApi } from './apps/asset/api';
import { BrokerMockApi } from './apps/broker/api';
import { MailboxMockApi } from './apps/mailbox/api';
import { PortfolioAssetsMockApi } from './apps/portfolio-assets/api';
import { PortfolioUsersMockApi } from './apps/portfolio-members/api';
import { PortfolioTradingPairMockApi } from './apps/portfolio-trading-pair/api';
import { PortfolioTradingPatternTimeFrameMockApi } from './apps/portfolio-trading-pattern-timeframe/api';
import { PortfolioTradingPatternMockApi } from './apps/portfolio-trading-pattern/api';
import { PortfolioTradingPlanMockApi } from './apps/portfolio-trading-strategy/api';
import { PortfolioTransactionsMockApi } from './apps/portfolio-transactions/api';
import { PortfolioMockApi } from './apps/portfolio/api';
import { ReportOverviewChartMockApi } from './apps/report-overview-chart/api';
import { ReportOverviewSummaryMockApi } from './apps/report-overview-summary/api';
import { ReportPatternMockApi } from './apps/report-pattern/api';
import { ReportTradingPairMockApi } from './apps/report-trading-pair/api';
import { TradeMockApi } from './apps/trade/api';
import { TradingPairAlertLogMockApi } from './apps/trading-pair-alert-log/api';
import { TradingPairAlertMockApi } from './apps/trading-pair-alert/api';
import { TradingPairBrokerMockApi } from './apps/trading-pair-broker/api';
import { TradingPairMockApi } from './apps/trading-pair/api';
import { ProjectMockApi } from './dashboard/api';

export const mockApiServices = [
    BrokerMockApi,
    AssetMockApi,
    //
    PortfolioMockApi,
    PortfolioTransactionsMockApi,
    PortfolioAssetsMockApi,
    PortfolioTradingPatternMockApi,
    PortfolioTradingPatternTimeFrameMockApi,
    PortfolioTradingPlanMockApi,
    PortfolioTradingPairMockApi,
    PortfolioUsersMockApi,

    //
    TradeMockApi,

    TradingPairMockApi,
    TradingPairBrokerMockApi,
    TradingPairAlertMockApi,
    TradingPairAlertLogMockApi,

    ReportOverviewSummaryMockApi,
    ReportOverviewChartMockApi,
    ReportTradingPairMockApi,
    ReportPatternMockApi,
    //
    MailboxMockApi,
    //
    ProjectMockApi,
    AuthMockApi,
    MessagesMockApi,
    NotificationsMockApi,
    UserMockApi,
];
