import { PortfolioTradingPair } from 'app/api/models/portfolio-trading-pair';

export const portfolioAssets: PortfolioTradingPair[] = [
    {
        id: '1',
        portfolio: {
            id: '1',
            name: 'portfolio 1',
        },
        brokerId: '3',
        //
        tradingPair: {
            id: '2',
            name: 'BTCUSDT',
            baseAssetSymbol: 'BTC', // BTC
            baseAssetPrecision: 2, // 0.01 => 2
            quoteAssetSymbol: 'USDT', // USD
            quoteAssetPrecision: 4, //0.0004 => 4
        },
        //
        quoteAssetBalanceAmount: 250,
    },
    {
        id: '2',
        portfolio: {
            id: '1',
            name: 'portfolio 1',
        },
        brokerId: '3',
        //
        tradingPair: {
            id: '2',
            name: 'WLDUSDT',
            baseAssetSymbol: 'WLD', // WLD
            baseAssetPrecision: 2, // 0.01 => 2
            quoteAssetSymbol: 'USDT', // USD
            quoteAssetPrecision: 4, //0.0004 => 4
        },
        //
        quoteAssetBalanceAmount: 250,
    },
    {
        id: '3',
        portfolio: {
            id: '1',
            name: 'portfolio 1',
        },
        brokerId: '3',
        //
        tradingPair: {
            id: '2',
            name: 'CAKESDT',
            baseAssetSymbol: 'CAKE', // WLD
            baseAssetPrecision: 2, // 0.01 => 2
            quoteAssetSymbol: 'USDT', // USD
            quoteAssetPrecision: 4, //0.0004 => 4
        },
        //
        quoteAssetBalanceAmount: 250,
    },
    {
        id: '4',
        portfolio: {
            id: '1',
            name: 'portfolio 1',
        },
        brokerId: '3',
        //
        tradingPair: {
            id: '2',
            name: 'LINKUSDT',
            baseAssetSymbol: 'LINK', // WLD
            baseAssetPrecision: 2, // 0.01 => 2
            quoteAssetSymbol: 'USDT', // USD
            quoteAssetPrecision: 4, //0.0004 => 4
        },
        //
        quoteAssetBalanceAmount: 250,
    },
    {
        id: '5',
        portfolio: {
            id: '1',
            name: 'portfolio 1',
        },
        brokerId: '3',
        //
        tradingPair: {
            id: '2',
            name: 'SOLUSDT',
            baseAssetSymbol: 'SOL', // WLD
            baseAssetPrecision: 2, // 0.01 => 2
            quoteAssetSymbol: 'USDT', // USD
            quoteAssetPrecision: 4, //0.0004 => 4
        },
        //
        quoteAssetBalanceAmount: 250,
    },
    {
        id: '6',
        portfolio: {
            id: '1',
            name: 'portfolio 1',
        },
        brokerId: '3',
        //
        tradingPair: {
            id: '2',
            name: 'TIAUSDT',
            baseAssetSymbol: 'TIA', // WLD
            baseAssetPrecision: 2, // 0.01 => 2
            quoteAssetSymbol: 'USDT', // USD
            quoteAssetPrecision: 4, //0.0004 => 4
        },
        //
        quoteAssetBalanceAmount: 250,
    },
];
