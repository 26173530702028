import { PortfolioTradingPatternTimeFrame } from 'app/api/models/portfolio-trading-pattern';

export const tradingPatterns: PortfolioTradingPatternTimeFrame[] = [
    {
        id: '1',
        //portfolio
        portfolio: {
            id: '1',
            name: 'portfolio 1',
        },
        //
        timeFramesEnabled: false,
        timeFrame: 'NONE',

        //long
        longSetup: true,
        longRisk: true,
        longMistake: true,
        //short
        shortSetup: true,
        shortRisk: true,
        shortMistake: true,

        name: 'FUNDEMENTAL',
        description: 'Entered trade based on fundemental research',
    },
    {
        id: '2',
        //portfolio
        portfolio: {
            id: '1',
            name: 'portfolio 1',
        },
        //
        timeFramesEnabled: false,
        timeFrame: 'NONE',

        //long
        longSetup: true,
        longRisk: false,
        longMistake: false,
        //short
        shortSetup: false,
        shortRisk: true,
        shortMistake: true,

        name: 'POSITIVE NEWS',
        description: 'Entered trade based on positive news',
    },
    {
        id: '3',
        //portfolio
        portfolio: {
            id: '1',
            name: 'portfolio 1',
        },
        //
        timeFramesEnabled: false,
        timeFrame: 'NONE',

        //long
        longSetup: false,
        longRisk: true,
        longMistake: true,
        //short
        shortSetup: true,
        shortRisk: false,
        shortMistake: false,

        name: 'NEGATIVE NEWS',
        description: 'Entered trade based on negative news',
    },
    {
        id: '4',
        //portfolio
        portfolio: {
            id: '1',
            name: 'portfolio 1',
        },
        //
        timeFramesEnabled: true,
        timeFrame: 'DAY_1',

        //long
        longSetup: true,
        longRisk: false,
        longMistake: false,
        //short
        shortSetup: false,
        shortRisk: true,
        shortMistake: true,

        name: 'HEIKIN ASHI - BUY',
        description: 'HeikinAshi - change from RED to GREEN',
    },
    {
        id: '5',
        //portfolio
        portfolio: {
            id: '1',
            name: 'portfolio 1',
        },
        //
        timeFramesEnabled: true,
        timeFrame: 'HOUR_12',

        //long
        longSetup: true,
        longRisk: false,
        longMistake: false,
        //short
        shortSetup: false,
        shortRisk: true,
        shortMistake: true,

        name: 'HEIKIN ASHI - BUY',
        description: 'HeikinAshi - change from RED to GREEN',
    },
    {
        id: '8',
        //portfolio
        portfolio: {
            id: '1',
            name: 'portfolio 1',
        },
        //
        timeFramesEnabled: true,
        timeFrame: 'DAY_1',

        //long
        longSetup: false,
        longRisk: true,
        longMistake: true,
        //short
        shortSetup: true,
        shortRisk: false,
        shortMistake: false,

        name: 'HEIKIN ASHI - SELL',
        description: 'HeikinAshi - change from GREEN to RED',
    },
    {
        id: '12',
        //portfolio
        portfolio: {
            id: '1',
            name: 'portfolio 1',
        },
        //
        timeFramesEnabled: true,
        timeFrame: 'DAY_1',

        //long
        longSetup: true,
        longRisk: false,
        longMistake: false,
        //short
        shortSetup: false,
        shortRisk: true,
        shortMistake: true,

        name: 'STOCH RSI - BUY',
        description: 'Stoch RSI - change from GREEN to RED',
        createdAt: '',
        createdBy: '',
        lastModifiedAt: '',
        lastModifiedBy: '',
    },
];
