import { PortfolioTradingPlan } from 'app/api/models/portfolio-trading-plan';

export const tradingStrategies: PortfolioTradingPlan[] = [
    {
        id: '1',
        //portfolio
        portfolio: {
            id: '1',
            name: 'portfolio 1',
        },
        //
        tradeCount: 1,
        efficiencyRatio: 0.24,
        //
        riskMaxPercent: 6,
        paternsMinCount: 2,

        name: 'Support & resistance',
        description: 'Entered trade based on fundemental research',

        checks: [
            {
                type: 'LONG',
                id: '1',
                description: 'Check divergence on 4h, 12h & D',
                orderSeq: 0,
            },
            {
                type: 'LONG',
                id: '2',
                description: 'Check if RSI is about to bounce',
                orderSeq: 1,
            },
            {
                type: 'LONG',
                id: '3',
                description: 'Check if support',
                orderSeq: 0,
            },
            {
                type: 'SHORT',
                id: '4',
                description: 'Check divergence on 4h, 12h & D',
                orderSeq: 0,
            },
            {
                type: 'SHORT',
                id: '5',
                description: 'CHeck if resistance is hit',
                orderSeq: 1,
            },
            {
                type: 'SHORT',
                id: '6',
                description: 'Check if shooting start is on timeframes',
                orderSeq: 2,
            },
        ],
    },
    {
        id: '2',
        //portfolio
        portfolio: {
            id: '1',
            name: 'portfolio 1',
        },
        //
        tradeCount: 1,
        efficiencyRatio: 0.24,
        //
        riskMaxPercent: 3,
        paternsMinCount: 2,

        name: 'Full detailed strategy',
        description: 'Entered trade based on fundemental research',

        checks: [
            {
                type: 'LONG',
                id: '1',
                description: 'Check divergence on 4h, 12h & D',
                orderSeq: 0,
            },
            {
                type: 'LONG',
                id: '2',
                description: 'Check if RSI is about to bounce',
                orderSeq: 1,
            },
            {
                type: 'LONG',
                id: '3',
                description: 'Check if support',
                orderSeq: 0,
            },
            {
                type: 'SHORT',
                id: '4',
                description: 'Check divergence on 4h, 12h & D',
                orderSeq: 0,
            },
            {
                type: 'SHORT',
                id: '5',
                description: 'CHeck if resistance is hit',
                orderSeq: 1,
            },
            {
                type: 'SHORT',
                id: '6',
                description: 'Check if shooting start is on timeframes',
                orderSeq: 2,
            },
        ],
    },
    {
        id: '3',
        //portfolio
        portfolio: {
            id: '1',
            name: 'portfolio 1',
        },
        //
        tradeCount: 1,
        efficiencyRatio: 0.24,
        //
        riskMaxPercent: 3,
        paternsMinCount: 2,

        name: 'Special trading strategy',
        description: 'Entered trade based on fundemental research',

        checks: [
            {
                type: 'LONG',
                id: '1',
                description: 'Check divergence on 4h, 12h & D',
                orderSeq: 0,
            },
            {
                type: 'LONG',
                id: '2',
                description: 'Check if RSI is about to bounce',
                orderSeq: 1,
            },
            {
                type: 'LONG',
                id: '3',
                description: 'Check if support',
                orderSeq: 0,
            },
            {
                type: 'SHORT',
                id: '4',
                description: 'Check divergence on 4h, 12h & D',
                orderSeq: 0,
            },
            {
                type: 'SHORT',
                id: '5',
                description: 'CHeck if resistance is hit',
                orderSeq: 1,
            },
            {
                type: 'SHORT',
                id: '6',
                description: 'Check if shooting start is on timeframes',
                orderSeq: 2,
            },
        ],
    },
];
