import { PortfolioAsset } from 'app/api/models/portfolio-asset';

export const portfolioAssets: PortfolioAsset[] = [
    {
        id: '1',
        portfolio: {
            id: '1',
            name: 'Portfolio 1',
        },
        //
        asset: {
            id: '2',
            symbol: 'EUR',
            name: 'Euro',
        },
        //
        balanceAmount: 250,
        balanceAmountInDefaultCurrency: 220,

        depositAmount: 100,
        withdrawalAmount: 50,

        realizedProfitAmount: 350,
        realizedLossAmount: 100,

        convertIncomingAmount: 40,
        convertOutgoingAmount: 200,
    },
    {
        id: '2',
        portfolio: {
            id: '2',
            name: 'Portfolio 2',
        },
        //
        asset: {
            id: '6',
            symbol: 'USD',
            name: 'Dollar',
        },
        //
        balanceAmount: 300,
        balanceAmountInDefaultCurrency: 300,

        depositAmount: 150,
        withdrawalAmount: 150,

        realizedProfitAmount: 100,
        realizedLossAmount: 50,

        convertIncomingAmount: 53,
        convertOutgoingAmount: 750,
    },
    {
        id: '3',
        portfolio: {
            id: '1',
            name: 'Portfolio 1',
        },
        //
        asset: {
            id: '3',
            symbol: 'BTC',
            name: 'Bitcoin',
        },
        //
        balanceAmount: 3,
        balanceAmountInDefaultCurrency: 62053.21,

        depositAmount: 0.3,
        withdrawalAmount: 1,

        realizedProfitAmount: 3.53452,
        realizedLossAmount: 0.98534,

        convertIncomingAmount: 0.8421,
        convertOutgoingAmount: 1.363,
    },
];
